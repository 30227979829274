import axios from 'axios'
import { getAuthToken } from '../../utils/auth.utils'

const randomText = (length) => {
  let result = ''
  const characters = '0123456789'
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length))
  }

  return result
}

const uploadFile = async (file, path) => {
  const fileType = file.name.split('.')
  const fileName = `${new Date().getTime()}${randomText(3)}.${fileType[fileType.length - 1]}`
  const formData = new FormData()

  formData.append('file', file)
  formData.append('location', path)
  formData.append('name', fileName)
  const auth = getAuthToken()
  const { data: { data } } = await axios.post(
    `${process.env.VUE_APP_UPLOAD_API}`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        [auth.key]: auth.value
      }
    }
  )

  return data
}

const uploadToBucket = async (file, path) => {
  let image = null
  try {
    const res = await uploadFile(file, path.replace(/\//g, ''))
    image = res
  } catch (e) {
    console.error('uploadToBucket catch', e)
  }
  return image
}

export default uploadToBucket

export { uploadToBucket }
